import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { AccessLevel } from '@/modules/common/enums/access-levels.enum'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import SecurityContext from '@/modules/common/services/security-context.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrNewUserForm'
})
export default class GtrNewUserForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Ref()
    readonly observerNewUserCompanyForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        newUserCompany: {
          name: null,
          email: null,
          two_factor_enabled: null,
          two_factor_method: null,
          two_factor_phone: null,
          access_level: null,
          company_uuid: this.$store.state.company.company.uuid,
          user_uuid: this.$store.state.company.company.owner.uuid,
          appointed_support_contact: null
        },
        two_factor_options: [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ],
        companySupportContact: false
      }
    }

    accessLevelActions (value) {
      if (value === 'SUPER_ADMIN') {
        this.$data.newUserCompany.two_factor_enabled = 1
        this.$data.two_factor_options = [
          { value: 1, text: 'Yes' }
        ]
      } else {
        this.$data.newUserCompany.two_factor_enabled = null
        this.$data.two_factor_options = [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ]
      }
    }

    onSMSChange () {
      if (!this.$data.newUserCompany.two_factor_enabled) {
        this.$data.newUserCompany.two_factor_method = null
        this.$data.newUserCompany.two_factor_phone = null
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    async mounted () {
      const securityContext = JSON.parse(Container.get(SecurityContext).context())
      const allAccessLevels = await this.getAllAccessLevels()

      if (securityContext) {
        const user = securityContext.user
        switch (user.access_level) {
          case AccessLevel.SUPER_ADMIN: {
            this.$data.accessLevels = allAccessLevels
            break
          }
          case AccessLevel.RESELLER_ADMIN: {
            this.$data.accessLevels = [
              { value: AccessLevel.RESELLER_ADMIN, text: 'Reseller' },
              { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          case AccessLevel.COMPANY_ADMIN: {
            this.$data.accessLevels = [
              // { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          // case AccessLevel.COMPANY_USER: {
          //   this.$data.accessLevels = [
          //     { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
          //   break
          // }
          default: {
            break
          }
        }
      }
    }

    private async getAllAccessLevels () {
      const response = await this.$store.dispatch('company/fetchUserGroups')
      const allAccessLevels: any[] = []
      for (const userGroupKey in response.data) {
        const userGroup = response.data[userGroupKey]
        allAccessLevels.push({ value: userGroup.name, text: userGroup.description })
      }
      return allAccessLevels
    }

    onCloseForm () {
      const observer = (this.$refs.observerNewUserCompanyForm as any)
      observer.reset()
      this.$data.newUserCompany = {
        name: null,
        email: null,
        two_factor_enabled: null,
        two_factor_method: null,
        two_factor_phone: null,
        access_level: null,
        company_uuid: this.$store.state.company.company.uuid,
        user_uuid: this.$store.state.company.company.owner.uuid,
        appointed_support_contact: null
      }
      this.$emit('close')
    }

    async submit () {
      try {
        this.$data.submitting = true
        this.$data.newUserCompany.admin_base_url = window.location.origin
        await this.$store.dispatch('company/addUser', this.$data.newUserCompany)
        Container.get(Notification).success('User successfully created.')
        this.$bus.$emit('gtr.level-two.new-user')
        this.$emit('close')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
        const errors = error.data.errors
        if (errors) {
          this.showServerErrors(errors)
        }
      } finally {
        this.clearFormAndModel()
        this.$data.submitting = false
      }
    }

    private showServerErrors (errors: Record<string, string>): void {
      this.observerNewUserCompanyForm.setErrors(errors)
    }

    private clearFormAndModel () {
      this.$data.newUserCompany = {
        name: null,
        email: null,
        two_factor_enabled: null,
        two_factor_method: null,
        two_factor_phone: null,
        access_level: null,
        company_uuid: this.$store.state.company.company.uuid,
        user_uuid: this.$store.state.company.company.owner.uuid,
        appointed_support_contact: null
      }
      this.observerNewUserCompanyForm.reset()
    }

    private updateUserSupportContact () {
      if (!this.$data.companySupportContact) {
        this.$data.newUserCompany.appointed_support_contact = 0
      } else if (this.$data.companySupportContact) {
        this.$data.newUserCompany.appointed_support_contact = 1
      }
    }
}
